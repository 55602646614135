<template>
  <div class="px-1 md:px-4 home_slider">
    <div class="swiper max-w-[100%] rounded-lg my-2 md:my-4">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <!-- elements with  "swiper-carousel-animate-opacity" class will have animated opacity -->
          <div class="swiper-carousel-animate-opacity">
            <img class="bg-image" src="../../../assets/banners/BannerCoins.webp" alt="" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-carousel-animate-opacity">
            <img class="bg-image" src="../../../assets/banners/BannerBeach.webp" alt="" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-carousel-animate-opacity">
            <img class="bg-image" src="../../../assets/banners/BannerTable.webp" alt="" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-carousel-animate-opacity">
            <img v-on:click="$router.push({ path: $route.path, query: { tab: 'weeklyRaffle' } })" class="bg-image cursor-pointer" src="../../../assets/banners/raffflev6.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <button v-tooltip="'Chat Rules'" v-on:click="showChatRules" class="hidden"></button>
</template>

<script type="module">
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

/**
 * import Carousel effect module
 */
import EffectCarousel from './effect-carousel.esm';
/**
 * import Swiper styles
 */
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'swiper/scss/virtual';
/**
 * import Carousel effect styles
 */
import './effect-carousel.scss';
/**
 * Custom local styles
 */

import './main.scss';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'HomeSlider',
  mounted() {
    const swiper = new Swiper('.swiper', {
      // pass EffectCarousel module to modules
      modules: [Autoplay, Navigation, Pagination],
      // specify "carousels" effect
      effect: 'carousel',
      grabCursor: true,
      loop: true,
      loopedSlides: 3,
      slidesPerView: isMobile ? 1 : 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      autoplay: {
        delay: 3000,
      },
    });

    swiper.autoplay.start();
  },
};
</script>
