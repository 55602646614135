<template>
  <aside>
    <div class="max-[767px]:my-4 my-2 md:mx-2 flex min-w-[60px] flex-col bg-secondary md:h-full" :class="generalS.showLeftMenu ? 'px-2 max-[767px]:pb-3 max-[767px]:pt-2' : 'h-full px-0'
      ">
      <div>
        <div class="mx-2 flex hidden flex-row items-center justify-between space-x-2 p-2">
          <p v-if="generalS.showLeftMenu" class="font-normal">Casino</p>
          <div v-on:click="toggleCasino" :class="generalS.showLeftMenu ? '' : 'mx-2'">
            <svg v-if="showCasino" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
          </div>
        </div>
      </div>
      <div v-if="showCasino">
        <div class="bg-secondary-light rounded-md mt-2"
          :class="generalS.showLeftMenu ? '' : 'items-center justify-center '">
          <div v-for="option in casinoOptions" v-on:click="onCasinoOptionClicked(option), closeMenu()"
            :key="option.name" :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
            <img v-tooltip="generalS.showLeftMenu ? '' : option.name" class="h-[1.6rem] max-[575px]:h-5"
              :src="option.icon" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              {{ option.name }}
            </p>
          </div>
          <div v-on:click="$router.push({ path: $route.path, query: { tab: 'weeklyRaffle' } })" :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
            <img v-tooltip="generalS.showLeftMenu ? '' : ''" class="h-[1.6rem] max-[575px]:h-5"
              src="../../assets/chancedTicket.png" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              5,000 SC Weekly Raffle
            </p>
          </div>
        </div>

        <span v-if="generalS.showLeftMenu" class="mx-4 border-b border-line-color hidden" />

        <div class="bg-secondary-light rounded-md mt-2"
          :class="generalS.showLeftMenu ? '' : 'items-center justify-center '">
          <router-link v-for="option in categoryOptions" v-on:click="closeMenu()" :to="'/casino/group/' + option.slug"
            :key="option.name" :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 p-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
            <img v-tooltip="generalS.showLeftMenu ? '' : option.name" :src=getCategoryIcon(option)
              @error="loadFallbackImage" :data-fallback="getDefaultCategoryIcon()" class="h-[1.6rem] max-[575px]:h-5" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              {{ option.name }}
            </p>
          </router-link>
          <router-link v-on:click="closeMenu()" :to="'/casino/group/trending-slots'" :key="'Trending Slots'"
            :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 p-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
            <img v-tooltip="generalS.showLeftMenu ? '' : 'Trending Slots'" :src="trendingIcon"
              @error="loadFallbackImage" :data-fallback="getDefaultCategoryIcon()" class="h-[1.6rem] max-[575px]:h-5" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              Trending Slots
            </p>
          </router-link>
          <router-link v-on:click="closeMenu()" :to="'/casino/group/lucky-slots'" :key="'Lucky Slots'"
            :class="generalS.showLeftMenu ? '' : 'justify-center'"
            class="flex cursor-pointer flex-row items-center space-x-2 p-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
            <img v-tooltip="generalS.showLeftMenu ? '' : 'Lucky Slots'" :src="luckyIcon" @error="loadFallbackImage"
              :data-fallback="getDefaultCategoryIcon()" class="h-[1.6rem] max-[575px]:h-5" />
            <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
              Lucky Slots
            </p>
          </router-link>
        </div>
      </div>

      <span class="mx-4 border-b border-line-color hidden" />


      <div class="bg-secondary-light rounded-md mt-2"
        :class="generalS.showLeftMenu ? '' : 'items-center justify-center '">
        <div v-on:click="showLiveChat(this.accountDeleteStatus)" :class="generalS.showLeftMenu ? '' : 'justify-center'"
          class="flex cursor-pointer flex-row items-center space-x-2 px-3 py-3 text-sm max-[575px]:py-2 hover:bg-[#191937]">
          <img v-tooltip="generalS.showLeftMenu ? '' : 'Live Support'" class="h-6 max-[575px]:h-5"
            :class="generalS.showLeftMenu ? '' : ''" src="../../assets/sidebar/live_support.png" />
          <p v-if="generalS.showLeftMenu" class="font-normal whitespace-nowrap">
            Live Support
          </p>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { generalStore } from '@/store/pinia/generalStore';
import { icons } from '../Shared/icons';
import { isMobileOnly } from 'mobile-device-detect';
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import { duelStore } from '@/store/pinia/duelStore';
import { getCategoryIcon, getDefaultCategoryIcon, loadFallbackImage } from "@/utils";
export default {
  name: 'LeftSideBar',

  setup() {

    const casinoOptions = [
      {
        icon: icons.Favourites,
        name: 'Favorites',
        route: '/casino/group/favorites',
        auth: true,
      },
      // {
      //   icon: icons.Recent,
      //   name: 'Recent',
      //   route: '/casino/group/recent',
      //   auth: true,
      // },
      {
        icon: icons.Challenges,
        name: 'Challenges',
        auth: true,
        route: '/challenges/all',
      },
      {
        icon: icons.ReferFriend,
        name: 'Refer A Friend',
        route: '/affiliates/new-referral-bonus',
        auth: true,
      },
      {
        icon: icons.giveaway,
        name: 'Giveaways',
        auth: true,
        route: '/giveaways/active',
      },
      {
        icon: icons.mybets,
        name: 'My Game Play',
        auth: true,
        route: '/my-game-play',
      },
    ];
    const generalS = generalStore();
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;

    const showLiveChat = function (isRestoredAccount) {
      if (isRestoredAccount == 3) {
        window.swal({
          icon: 'info',
          title: `Support`,
          text: `For support related inquiries please contact help@chanced.com`,
        });
      } else {
        window.Intercom('showMessages');
      }
    };
    const showPromotions = ref(true);
    const showCasino = ref(true);

    const toggleCasino = function () {
      showCasino.value = !showCasino.value;
    };
    const togglePromotions = function () {
      showPromotions.value = !showPromotions.value;
    };
    const duelS = duelStore();

    return {
      duelS,
      icons,
      casinoOptions,
      generalS,
      isLandscape,
      showLiveChat,
      showPromotions,
      showCasino,
      toggleCasino,
      togglePromotions,
      trendingIcon: icons.Trending,
      luckyIcon: icons.Lucky,
    };
  },
  data() {
    return {
      wars: [],
      accountDeleteStatus: 0,
    };
  },
  created() {


    // if (isMobileOnly) {
    //   this.generalS.showLeftMenu = true;
    //   this.generalS.showChat = false;
    // }
    // this.updateWars();
    this.accountDeleteStatus = this.me.delete_status;
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'categoryOptions', 'iAmStaff']),
    pvpCount() {
      return this.duelS.duels.length + this.wars.length;
    },
  },
  methods: {
    getCategoryIcon,
    loadFallbackImage,
    getDefaultCategoryIcon,
    onCasinoOptionClicked(option) {
      if (option.auth && !this.isLoggedIn) {
        this.$router.push(this.$route.path + '?tab=login');
        return;
      }

      if (!option.route) {
        this.$toast.default('Coming soon');
      }

      this.$router.push(option.route);
    },
    updateWars() {
      // new war created event
      window.socket.on('war-games-lobby', (game) => {
        this.wars.push(game);
      });

      window.socket.on('war-game-ended', ({ gameId }) => {
        let game = this.lobby.find((g) => g.id === gameId);
        if (game) {
          this.wars.splice(this.wars.indexOf(game), 1);
        }
      });
    },
    playResponsibly() {
      this.$router.push({ path: '/responsible-gaming' });
    },
    closeMenu() {
      if (window.innerWidth <= 1024 && window.innerWidth > 767) {
        this.generalS.showLeftMenu = false;
      }
    },
  },
};
</script>
